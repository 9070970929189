// import Bouncer from "./components/bouncer";
// import Models from "./components/models";
// import PeppersGhost from "./components/peppersghost";
// import ThreeScene from "./components/Three";

import EyeModel from "./components/EyeModel";
import NewModel from "./components/NewModel";

function App() {
  return (
    <div className="m-0 p-0">
      {/* <Models /> */}
      {/* <NewModel /> */}
      <EyeModel />
    </div>
  );
}

export default App;
