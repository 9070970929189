import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const EyeModel = () => {
  const canvas = useRef(null);
  useEffect(() => {
    //SCENE
    const scene = new THREE.Scene();

    //CAMERA
    const camera = new THREE.PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.z = 500;

    //LIGHT
    const topLight = new THREE.DirectionalLight(0xffffff, 1); // (color, intensity)
    topLight.position.set(200, 200, 200); //top-left-ish
    topLight.castShadow = true;
    scene.add(topLight);

    const ambientLight = new THREE.AmbientLight(0x333333, 1);
    scene.add(ambientLight);

    //RENDERER
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas.current,
      //   alpha: true,
    });
    renderer.setSize(window.innerWidth, window.innerHeight);

    let mouseX = window.innerWidth;
    let mouseY = window.innerHeight;
    let eye;
    //LOADER
    const loader = new GLTFLoader();
    loader.load("./assets/eye/scene.gltf", (gltf) => {
      eye = gltf.scene;
      eye.rotation.y = 4.75;

      scene.add(eye);

      if (window.innerWidth < 600) {
        eye.scale.set(0.7, 0.7, 0.7);
        eye.position.y = 50;
      } else {
        eye.position.y = 30;
      }
    });

    // CONTROLS
    const controls = new OrbitControls(camera, canvas.current);
    controls.enableDamping = true;

    //RESIZE ON WINDOW RESIZE
    window.addEventListener("resize", () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });

    //ANIMATION
    const animate = () => {
      controls.update();
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    animate();
  }, []);

  return (
    <>
      {" "}
      <h1 className="relative text-center font-bold text-xl text-white z-50 top-0 left-0 bg-black py-2">
        SIMPLE THREE.JS WEBSITE
        <p className="text-sm font-light">
          Created by{" "}
          <a
            href="https://cermuel.vercel.app"
            className="text-blue-600 font-semibold cursor-pointer"
          >
            CERMUEL
          </a>{" "}
        </p>
      </h1>
      <canvas ref={canvas} className="z-10"></canvas>
    </>
  );
};

export default EyeModel;
